import React, { Component } from 'react'
import FullCalendar from '@fullcalendar/react'
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid"
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import scrollGridPlugin from "@fullcalendar/scrollgrid"
// import rrulePlugin from '@fullcalendar/rrule'
import "./Calendar.scss"
import moment from "moment"
import { events } from "./config"
import { ScheduleMeetingData } from '../ScheduleMeeting/Config'
import CalendarHeader from './CalendarHeader';
import Event from "./Event"
import Resource from './Resource'
import SchedulerService from "../../services/service"
import * as Constants from '../../constants/constants'
import Loader from '../../Common/Loader/Loader';
import { withUserContext } from '../../contexts/UserContext';
import MainViewModalComponent from '../../Common/MainViewModalLayout/Modal/Modal';
import MessageModal from '../SharedComponents/MessageModal';
import { MDBRadio } from 'mdb-react-ui-kit'
import { FULL_CALENDAR_KEY } from '../../constants/constants';
export default withUserContext(class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "LOADING",
      showPageLoader: false,
      minTime: null,
      maxTime: null,
      avaialabilityData: [],
      timeSlotData: [],
      occupancyData: [],
      groupData: [],
      resources: [],
      events: [],
      isFirstLoaded: false,
      resourceRefresh: false,
      groupRefresh: false,
      meetingRefresh: false,
      gotoDate: null,
      getGroupData: () => this.getGroupData(),
      getDashboardData: () => this.getDashboardData(),
      gotoDatefn: (date) => this.gotoDate(date),
      onResourceChange:()=>this.onResourceChange(),
      showConfirmTypePopup: false,
      editType: null,
      eventClickInfo: null,
      modalType: null,
      showRadioError: false,
      showDeleteConfirmationPopup: false,
      showSuccess: false,
      showFail: false,
      showAccessDenied:false,
      saveTitle: "Success",
      saveEditMessage: "The changes are saved successfully!",
      failTitle: "Failure",
      failMessage: "Saving changes failed!",
      permissionDenied:"You don't have permission to edit or delete!",
      subjectClassName: "truncate"
    }
    this.calendarRef = React.createRef();
  }
  static getDerivedStateFromProps(props, state) {
    if ((props.refreshPage?.value !== state.resourceRefresh) && props.refreshPage?.modal === 'Resource') {
      if (props.refreshPage?.value) {
        // state?.getDashboardData();
        state?.onResourceChange();
        props.toggleRefreshPage(props.refreshPage?.modal)
      }
      return {
        resourceRefresh: props.refreshPage?.value || false
      }
    }
    else if ((props.refreshPage?.value !== state.groupRefresh) && props.refreshPage?.modal === 'Group') {
      if (props.refreshPage?.value) {
        state?.getGroupData();
        state?.onResourceChange();
        props.toggleRefreshPage(props.refreshPage?.modal)
      }
      return {
        groupRefresh: props.refreshPage?.value || false
      }
    }
    else if ((props?.refreshPage?.value !== state?.meetingRefresh) && props.refreshPage?.modal === "Meeting") {
      if (props.refreshPage?.value) {
        state?.gotoDatefn(props.refreshPage?.date);
        props.toggleRefreshPage(props.refreshPage?.modal)
      }
    }

    return null;
  }
  componentDidMount() {
    // did mounted
    this.getStaticData("lu_timing")
    this.getStaticData("lu_availability")
    this.getStaticData("lu_time_slot")
    this.getGroupData();
  }
  componentDidUpdate(prevProps, prevState) {
    // for page load call API after gettting tenant_id
    // console.log("did update:",prevProps?.userContext?.active_tenant?.tenant_id ," ", this.props?.userContext?.active_tenant?.tenant_id)
    if (prevProps?.userContext?.active_tenant?.tenant_id !== this.props?.userContext?.active_tenant?.tenant_id) {
      this.getStaticData("lu_timing")
      this.getStaticData("lu_availability")
      this.getStaticData("lu_time_slot")
      this.getGroupData();
    }
  }
  getGroupData() {
    SchedulerService.getData(Constants.SchedulerServiceBaseUrl + "/group?groupId=null", this.props?.userContext?.active_tenant?.tenant_id)
      .then((response) => {
        let groupData = response?.data?.map(item => ({ value: item?.group_id, label: item?.group_name }))
        this.setState({ groupData: groupData })
      }, err => {
        console.log("err", err)
      })
  }
  getStaticData(tableName) {
    // alert(this.props?.userContext?.active_tenant?.tenant_id);
    SchedulerService.getData(Constants.SchedulerServiceBaseUrl + "/staticData?staticTable=" + tableName, this.props?.userContext?.active_tenant?.tenant_id)
      .then((response) => {
        console.log(response.data)
        if (response?.data?.error) {
          return;
        }
        if (tableName === "lu_timing") {
          this.setState({ minTime: response?.data?.[0]?.name, maxTime: response?.data?.[1]?.name })
        }
        else if (tableName === "lu_availability") {
          let formattedList = response?.data?.map(item => ({ value: item.name, label: `${item?.name} ${item?.name  === 30 ? 'minute' : 'hour'}` }))
          // this.setState({avaialabilityData:[...{value:null,label:'Availability'},...formattedList]})
          this.setState({ avaialabilityData: formattedList })
        }
        else {
          let formattedList = response?.data?.map(item => ({ value:  item.name === 30 || item.name === 15 ? item.name : item.name * 60, label: item.name === 30 || item.name === 15 ? `${item.name} Minute`:`${item?.name} hour` }))
          this.setState({ timeSlotData: formattedList })
        }
      }, err => {
        console.log("err", err)
      })
  }
  getResourceData() {
    this.setState({ showPageLoader: true });
    SchedulerService.getData(Constants.SchedulerServiceBaseUrl + "/resource?resourceId=null", this.props?.userContext?.active_tenant?.tenant_id)
      .then((response) => {
        console.log(response)
        let data = response.data;
        let occupancyData = [];
        let formattedList = data?.map(item => {
          occupancyData.push({ value: item?.occupancy, label: item?.occupancy })
          return {
            id: item?.resource_id,
            title: item?.resource_name,
            extendedProps: { bgColor: item?.color },
            eventBackgroundColor: item?.color,
            ...item
          }
        })
        this.setState({ resources: formattedList, occupancyData: occupancyData })
        this.getDashboardData(moment().format("YYYY-MM-DD"));

      }, err => {
        this.setState({ showPageLoader: false });
        console.log(err)
      })
  }
  onResourceChange(){
    let startDate;
    if (this.calendarRef.current._calendarApi.view.type === "resourceTimeGridWeek") {
      startDate = moment(this.calendarRef.current._calendarApi?.view?.activeStart).format("YYYY-MM-DD");    
    } else {
      startDate = moment(this.calendarRef.current._calendarApi?.getDate()).format("YYYY-MM-DD");
    }
    this.gotoDate(startDate);
  }
  gotoDate(date) {
    if (date)
      this.calendarRef.current._calendarApi.gotoDate(date);
    this.changeDate();
  }
  classNameForSubject = (type) => {
    if (type === "day") {
      this.setState({ subjectClassName: "truncate"})
    }
    else {
      this.setState({ subjectClassName: "no-truncate"})
    }
  }
  getDashboardData(startDate, endDate = null, groupId = null, occupancyMin = null, occupancyMax= null,avaialability = null,viewMyMeetings) {
    this.setState({ showPageLoader: true });
    if (!startDate) {
      if (this.calendarRef.current._calendarApi.view.type === "resourceTimeGridWeek") {
        startDate = moment(this.calendarRef.current._calendarApi?.view?.activeStart).format("YYYY-MM-DD");
        endDate = moment(this.calendarRef.current._calendarApi?.view?.activeEnd).format("YYYY-MM-DD");
      } else {
        startDate = moment(this.calendarRef.current._calendarApi?.getDate()).format("YYYY-MM-DD");
      }
    }
    let queryParams = {
      start_date: startDate,
      end_date: !endDate ? startDate : endDate,
      group_id: groupId,
      occupancy_min: occupancyMin,
      occupancy_max:occupancyMax,
      avaialability: avaialability,
      avaialability_type: "HOURLY"
    }
    SchedulerService.getDataParams(Constants.SchedulerServiceBaseUrl + "/dashboard", queryParams, this.props?.userContext?.active_tenant?.tenant_id)
      .then((response) => {
        console.log(response)
        let data = response.data;
        let events = [];
        let resources = [];
        let occupancyData = data?.occupancy?.map(o => ({ value: o, label: o }));
        data?.results?.map(obj => {
          // let startTime = moment(item?.date).format("YYYY-MM-DD") +" "+item?.start_time;
          // let endTime = moment(item?.date).format("YYYY-MM-DD") +" "+item?.end_time;
          // if (occupancyData?.findIndex(x => x.value === obj?.occupancy) === -1) {
          //   occupancyData.push({ value: obj?.occupancy, label: obj?.occupancy })
          // }
          resources.push({
            id: obj?.resource_id,
            title: obj?.resource_name,
            extendedProps: { bgColor: obj?.color+"b8" },
            eventBackgroundColor: obj?.color+'b8',
            occupancy: obj?.occupancy,
            technology: obj?.technology
          })
          obj?.meetings?.map(item => {
            events.push({
              id: item?.meeting_id,
              start: item?.start_date_time,
              end: item?.end_date_time,
              resourceId: obj?.resource_id,
              title: item?.subject,
              timeText: `${moment(item?.start_time, "hh:mm:ss").format("hh:mmA")} - ${moment(item?.end_time, "hh:mm:ss").format("hh:mmA")}`,
              author: item?.author,
              authorId:item?.author_id,
              is_recurring: item?.is_recurring === 1 ? true : false,
              meetingsClassName: this.state.subjectClassName,
              meetingDaysId: item?.schedule_meeting_days_id,
              editClick: this.editClick.bind(this),             
              canEditMeeting: this.props?.userContext?.active_tenant?.permissions?.canEditMeetings,
              canDeleteMeeting: this.props?.userContext?.active_tenant?.permissions?.canDeleteMeetings,
              canViewSubject:this.props?.userContext?.active_tenant?.role?.toLowerCase() === "admin" || this.props?.userContext?.user_profile?.user_id === item?.author_id


            })

          })
          
        })
        if (viewMyMeetings){
          let loggedInUserId = this.props?.userContext?.user_profile?.user_id
          let myEvents = events?.filter(item => item.authorId == loggedInUserId)
           this.setState({events: myEvents})
          }
          else{
            this.setState({events:events})
          }
        // || JSON.parse(JSON.stringify(this.state?.occupancyData)) !== JSON.parse(JSON.stringify(occupancyData))
        // if (!this.state?.isFirstLoaded) {
        //   this.setState({ isFirstLoaded: true, occupancyData: occupancyData })
        // }
        this.setState({ showPageLoader: false, resources: [...resources],  occupancyData: occupancyData });
        if (this.calendarRef.current._calendarApi.view.type === "resourceTimeGridWeek") {
        this.calendarRef.current._calendarApi.changeView('resourceTimelineDay'); 
        this.calendarRef.current._calendarApi.changeView('resourceTimeGridWeek'); 
        }else{
          this.calendarRef.current._calendarApi.changeView('resourceTimeGridWeek'); 
          this.calendarRef.current._calendarApi.changeView('resourceTimelineDay'); 
        }
        // this.calendarRef.current._calendarApi.destroy();
        // this.calendarRef.current._calendarApi.render();
      }, err => {
        this.setState({ showPageLoader: false });
        console.log(err)
      })
  }
  fieldChanged = (value, mode = "error", message) => {
    switch (mode) {
      case "success": return this.setState({ showSuccess: value });
      case "fail": return this.setState({ showFail: value });
    }
  }
  closPopup = () => {
    this.setState({ showFail: false, showSuccess: false });
    // if (this.state.showSuccess) {
    //     this.props?.handleClose();
    // }       
  }
  radioChange(type) {
    this.setState({ editType: type })
  }
  editClick(eventClickInfo, type) {
    let isAdmin = this.props?.userContext?.active_tenant?.role?.toLowerCase() === "admin";
    let isAuthor = this.props?.userContext?.user_profile?.user_id === eventClickInfo?.event?.extendedProps?.authorId;
    if(isAdmin || isAuthor){
      if (eventClickInfo?.event?.extendedProps?.is_recurring) {
        this.setState({ eventClickInfo: eventClickInfo, modalType: type, showConfirmTypePopup: true });
      }
      else {
        if (type === 'edit') {
          this.setState({ eventClickInfo: eventClickInfo, modalType: type }, () => {
            this.props?.handleShowScheduleMeetingEditPopup(eventClickInfo?.event?.id, this.state?.eventClickInfo?.event?.extendedProps?.meetingDaysId, 'SINGLE')
          });
        } else {
          this.setState({ eventClickInfo: eventClickInfo, modalType: type, showDeleteConfirmationPopup: true });
        }
  
      }
    }
    else {
      this.setState({ showAccessDenied:true });
        this.fieldChanged(true, "fail");
    }
    
  }
  onConfirm() {
    if (this.state?.showDeleteConfirmationPopup) {
      this.deleteEvent();
    } else {
      if (this.state?.modalType === "edit" && this.state?.editType) {
        this.setState({ showConfirmTypePopup: false })
        this.props?.handleShowScheduleMeetingEditPopup(this.state?.eventClickInfo?.event?.id, this.state?.eventClickInfo?.event?.extendedProps?.meetingDaysId, this.state?.editType)
      } else if (this.state?.modalType === 'delete') {
        if (this.state?.eventClickInfo?.event?.extendedProps?.is_recurring && this.state?.editType) {
          this.setState({ showDeleteConfirmationPopup: true })
        }
        else {
          this.setState({ showRadioError: true })
        }
      }
      else {
        this.setState({ showRadioError: true })
      }
    }
  }
  onCancel() {
    this.setState({ showAccessDenied:false, showConfirmTypePopup: false, eventClickInfo: null, showDeleteConfirmationPopup: false,modalType: null, showRadioError: false, editType:null })
  }
  deleteEvent() {
    this.setState({ showPageLoader: true });
    let postData = {
      schedule_meeting_id: this.state?.editType === 'SERIES' ? this.state?.eventClickInfo?.event?.id : null,
      schedule_meeting_days_id: this.state?.editType === 'SERIES' ? null : this.state?.eventClickInfo?.event?.extendedProps?.meetingDaysId,
      is_occurence_delete: this.state?.editType === 'SERIES' ? 0 : 1
    }
    SchedulerService.postDataParams(`${Constants.SchedulerServiceBaseUrl}/scheduleMeetingDelete`, postData, this.props?.userContext?.active_tenant?.tenant_id)
      .then((response) => {
        this.setState({ showPageLoader: false, showDeleteConfirmationPopup: false, showConfirmTypePopup: false, modalType: null });
        if (response?.data?.error_code || response.data.error) {
          this.fieldChanged(true, "fail", response?.data?.error_code ? response?.data?.error_message : undefined);
          return;
        }
        console.log(response);
        this.fieldChanged(true, "success");
        this.props?.toggleRefreshPage("Resource");
      }, err => {
        this.setState({ showPageLoader: false, showDeleteConfirmationPopup: false, showConfirmTypePopup: false });
        this.fieldChanged(true, "fail");
        console.log('err', err)
      })
  }
  render() {
    let message = this.state.showFail ? this.state?.showAccessDenied ? this.state?.permissionDenied:  this.state.failMessage : this.state?.showSuccess ? this.state?.saveEditMessage : "";
    let comfirmModalTitle = this.state.showFail ? this.state.failTitle : this.state.showSuccess ? this.state.saveTitle : "";
    return (
      <>
        <Loader isOpen={this.state.showPageLoader || false} text={this.state.mode === 'LOADING' ? "Loading..." : "Removing..."} />
        <MessageModal
          open={this.state?.showFail || this.state?.showSuccess || false}
          title={comfirmModalTitle}
          message={message}
          hideCancel={this.state.showFail || this.state.showSuccess || false}
          primaryButtonText={"OK"}
          secondaryButtonText={"Cancel"}
          onConfirm={() => this.closPopup()}
          handleClose={(e) => this.setState({ showAccessDenied:false, showFail: false, showSuccess: false })} />
        <MessageModal
          open={this.state.showConfirmTypePopup || this.state?.showDeleteConfirmationPopup || false}
          title={"Confirmation"}
          message={this.state?.showDeleteConfirmationPopup ? 'Are you sure you want to delete?' : null}
          primaryButtonText={"OK"}
          secondaryButtonText={"Cancel"}
          onConfirm={() => this.onConfirm()}
          handleClose={() => this.onCancel()}
        >
          {!this.state?.showDeleteConfirmationPopup && <>

            <span>This is one event in a series. what do you want to {this.state?.modalType} ?</span>
            <MDBRadio
              label='This one Event'
              value='SINGLE'
              name="editType"
              onChange={(e) => this.radioChange(e.target.value)}
            />
            <MDBRadio
              label='Entire Series'
              value='SERIES'
              name="editType"
              onChange={(e) => this.radioChange(e.target.value)}
            />
            {this.state?.showRadioError && !this.state?.editType && <span className='errorMsg'>Please select event type</span>}
          </>}
        </MessageModal>

        <div className='firstCalendar'>
          <CalendarHeader
            className='CalendarHeader'
            calendarRef={this.calendarRef}
            employeeNames={[]}
            type={'timegrid'}
            groupData={this.state?.groupData || []}
            occupancyData={this.state?.occupancyData || []}
            noOfResource={this.state?.resources?.length}
            showScheduleMeetingPopup={this.props?.handleShowScheduleMeetingPopup}
            onChangeHandler={this.getDashboardData.bind(this)}
            avaialabilityData={this.state?.avaialabilityData || []}
            timeSlotData={this.state?.timeSlotData || []}
            canAddMeeting={this.props?.userContext?.active_tenant?.permissions?.canAddMeetings}
            changeDate={fn => this.changeDate = fn}
            classNameForSubject={this.classNameForSubject}
          />
          <div id="calendarContainer">
            <FullCalendar
              schedulerLicenseKey={FULL_CALENDAR_KEY}
              ref={this.calendarRef}
              plugins={[resourceTimeGridPlugin, resourceTimelinePlugin,scrollGridPlugin]}
              initialView='resourceTimelineDay'
              resources={this.state?.resources || []}
              events={this.state?.events}
              eventContent={Event}
              resourceLabelContent={Resource}
              resourceLabelDidMount={(info) => { info.el.style.backgroundColor = info.resource.extendedProps?.bgColor }} 
              // resourceAdd = {(el)=>console.log(el,'resourceAdd')}             
               height={'70vh'}
              slotLabelFormat={{
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: true,
                meridiem: 'long'
              }}
              // contentHeight={'auto'}
              // slotLabelInterval={30}
              slotDuration={'00:15:00'}
              // width={'90vw'}
              
              // scrollTime={'00:00'}
              // stickyFooterScrollbar={true}
              stickyHeaderDates={true}
              dayMinWidth={150} // will cause horizontal scrollbars
              // aspectRatio={1.7}
              displayEventTime={false}
              // datesAboveResources={true}
             
              // resourceLabelDidMount={}
              resourceAreaWidth={'15%'}
              headerToolbar={false}
              {...this.state?.minTime ? { slotMinTime: this.state?.minTime } : null}
              {...this.state?.maxTime ? { slotMaxTime: this.state?.maxTime } : null}
              // allDay={true}    
              allDaySlot={false}
              weekends={false}
           
            />
          </div>
        </div>
        <br />
        <br />


      </>
    )
  }
});
