export const GroupTableConfig = {
    iscolumnFilterRequired: false,
    headings: [
        {
            headingLabel: "Group Name",
            width: "20%",           
            dataNodes:'group_name',          
            icon:'sort-alpha-down'
           
        },
        {
            headingLabel: "Resources",
            width: "70%",
            dataNodes:'resources'
           
        },
        {
            headingLabel: "",
            width: "10%"
           
        }
       
    ],
    hyperlinks:["group_name"],
    dataNodes: ["group_name", "resources"],
    primaryKey: "group_id",
    actions: ["trash-alt"],
}
export const GroupList=[
    {
        group_id:1,
        group_name:'Group 1',
        resources:'Resource 1, Resource 2, Resource 3'

    },
    {
        group_id:2,
        group_name:'Group 2',
        resources:'Resource 1'

    },
    {
        group_id:3,
        group_name:'Group 3',
        resources:'Resource 1, Resource 2'

    },
    {
        group_id:4,
        group_name:'Group 4',
        resources:'Resource 1, Resource 2'

    },
    {
        group_id:5,
        group_name:'Group 5',
        resources:'Resource 1, Resource 2'

    }
   
   
]

export const GroupDetails={
    group_id:null,
    group_name:null,
    resources:[]
}
export const ResourcesData =[
    {
        resource_id:1,
        resource_name:'Resource 1'
    },
    {
        resource_id:2,
        resource_name:'Resource 2'
    },
    {
        resource_id:3,
        resource_name:'Resource 3'
    },
    {
        resource_id:4,
        resource_name:'Resource 4'
    },
    {
        resource_id:5,
        resource_name:'Resource 5'
    },
    {
        resource_id:6,
        resource_name:'Resource 6'
    }

]
