export const CalendarConfig={
        initialView:'resourceTimelineDay',
        contentHeight:'auto',        
        displayEventTime:false,       
        resourceAreaWidth:'15%',
        headerToolbar:false,
        slotMinTime:'06:00:00',
        slotMaxTime:'20:00:00',       
        allDaySlot:false,
        weekends:false,
        resourceAreaColumns:[{width:'400px'}]
}
export const events = [
    {
      "resourceId": 2,
      title: 'my recurring event',
      rrule: {
        freq: 'weekly',
        dtstart: '2012-02-01'
      },
      exdate: ['2012-02-08'] // will also accept a single string
    },
    {
      "resourceId": 2,
      "title": "event 1",
      // "start":"2022-08-26 06:00:00",
      // "end":"2022-08-31 08:00:00",
      "startRecur":"2022-09-09",
      "endRecur":"2022-09-15",
      "startTime": "06:00:00",
      "endTime": "08:00:00",
      // "daysOfWeek":[2],
      // "backgroundColor":"#000000",
      // "borderColor":"#000000",
      "textColor":"#000000",      
      "extendedProps":{
        "description" : "Birthday Party11",
      }
    },
    {
      "resourceId": "b",
      "title": "event 3",
      "start": "2022-08-17T12:00:00+00:00", 
      "end": "2022-08-18T06:00:00+00:00",
      "extendedProps":{
        "description" : "Birthday Party",
      }
    },
    {
      "resourceId": "c",
      "title": "event 4",
      "start": "2022-08-17T07:30:00+00:00",
      "end": "2022-08-17T09:30:00+00:00",
      "extendedProps":{
        "description" : "Birthday Party",
      }
    },
    {
      "resourceId": "d",
      "title": "event 5",
      "start": "2022-08-17T10:00:00+00:00",
      "end": "2022-08-17T15:00:00+00:00"
    },
    {
      "resourceId": "a",
      "title": "event 2",
      "start": "2022-08-17T09:00:00+00:00",
      "end": "2022-08-17T14:00:00+00:00",
      "extendedProps":{
        "description" : "Birthday Party",
      }
    }
  ]
  export const DaySelect = [
    {label:'Daily', value:'day'},
    {label:'Weekly', value:'week'}
  ]
  export const TimeSlot= [
    {label:'1 hour', value:'60'},
    {label:'2 hours', value:'120'},
    {label:'3 hours', value:'180'},
    {label:'4 hours', value:'240'}
  ]
  export const Availability= [
    {label:'1 hour', value:1},
    {label:'2 hours', value:2},
    {label:'3 hours', value:3},
    {label:'4 hours', value:4},
    {label:'5 hours', value:5}
  ]